<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="6">
        <h3 class="headline" v-html="$t('Common.invitations_title')"></h3>
      </v-col>
    </v-row>
    <v-row v-if="inviteInList">
      <v-col
        v-for="(invite, index) in inviteInList"
        cols="12"
        sm="6"
        class="ma-4 pa-2"
        :key="index"
      >
        <v-card v-if="invite.from">
          <div class="user-image text-center mb-3" v-if="invite.from && invite.from.avatar">
            <img
              :src="invite.from.avatar"
              class="img-responsive rounded-circle"
              alt="user images"
              width="95"
              height="95"
            />
          </div>
          <div class="user-list-content">
            <div class="text-center">
              <h3 class="fw-bold">{{ invite.from.email }}</h3>
              <p>{{ invite.message }}</p>
            </div>
            <v-card-text>{{ invite.updatedAt | moment('from') }}</v-card-text>
            <v-footer absolute height="auto">
              <v-card-actions>
                <v-btn
                  color="success white--text "
                  :to="'/invite/accept?invitation_id=' + invite.id"
                >Accept</v-btn>
                <v-btn color="cyan white--text mx-1" @click="ignore(invite.id)">Ignore</v-btn>
              </v-card-actions>
              <v-spacer></v-spacer>
              <v-btn small :to="'/profile/' + invite.sender">View Profile</v-btn>
            </v-footer>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="inviteOutList">
      <v-col cols="12">
        <h3>{{ $t('Common.invitation_sent')}}</h3>
        <v-btn class="mb-3" dark color="takein_logo" to="/invite">Send Invitation</v-btn>
      </v-col>
      <v-col cols="6">
        <v-data-table
          hide-default-footer
          :headers="headers"
          :items="inviteOutList"
          class="elevation-1"
        >
          <template v-slot:body="{items}">
            <tbody>
              <tr v-for="(item, index) in items" :key="`inviteOutList-${index}`">
                <td>{{ item.to }}</td>
                <td>{{ item.message }}</td>
                <td>{{ item.updatedAt | moment('from') }}</td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        { text: "To", value: "to" },
        { text: "Message", value: "message" },
        { text: "Invite Date" }
      ],
      loader: true,
      inviteInList: null,
      inviteOutList: null,
      connectInvitesList: null
    };
  },
  mounted() {
    this.getInvites();
  },
  methods: {
    ignore(id) {
      this.$store.dispatch("ignoreInvite", id).then(e => {
        this.getInvites();
      });
    },
    getInvites() {
      this.$store.dispatch("getInvites", { type: "in" }).then(list => {
        this.inviteInList = list;
      });
      this.$store.dispatch("getInvites", { type: "out" }).then(list => {
        this.inviteOutList = list;
      });
    }
  }
};
</script>
